document.addEventListener("DOMContentLoaded", () => {

    // homevideo play
    let v1 = document.getElementById("homevid");
    if (v1) {
        let source = document.createElement('source');
        let src = (winw > 700 ? v1.dataset.d : v1.dataset.m);
        source.src = src;
        source.type = 'video/mp4';
        v1.appendChild(source);
    }
    let v2 = document.getElementById("uslvid");
    if (v2) {
        let source = document.createElement('source');
        let src = (winw > 700 ? v2.dataset.d : v2.dataset.m);
        source.src = src;
        source.type = 'video/mp4';
        v2.appendChild(source);
    }

    // Mail send
    const mailEndPoint = `/send.php`;
    const sendMailWrapper = document.getElementById("ContactMe");
    const sendMailBtn = document.getElementById("u_s");
    const alertsWrapper = document.getElementById("Feedback");

    // Fields
    const botTrap1 = document.getElementById("email");
    const botTrap2 = document.getElementById("name");
    const nameInput = document.getElementById("u_n");
    const companyInput = document.getElementById("u_c");
    const phoneInput = document.getElementById("u_p");
    const emailInput = document.getElementById("u_e");
    const txtInput = document.getElementById("u_t");

    // Required fields
    const checkFieldsInfo = document.querySelector("[data-checkfields]");
    const infoTxt = checkFieldsInfo.querySelector("span");
    const requiredFields = [nameInput, companyInput, emailInput, txtInput];

    requiredFields.forEach((field) => {

        field.addEventListener("focus", () => {
            field.classList.remove('invalid');
            // field.classList.remove('valid');
        });
        field.addEventListener("focusout", () => {
            if (field.validity.valid) {
                field.classList.add('valid');
            } else {
                field.classList.remove('valid');
                field.classList.add('invalid');
            }
            if (requiredFields.every((f) => f.validity.valid)) {
                sendMailBtn.disabled = false;
                infoTxt.style.display = "none";
            } else {
                sendMailBtn.disabled = true;
            }
        });
    });
    sendMailBtn.parentElement.addEventListener("mouseover", () => {
        // sendMailBtn.focus();
        nameInput.blur();
        companyInput.blur();
        emailInput.blur();
        txtInput.blur();
        infoTxt.style.display = requiredFields.every((f) => f.validity.valid) ?
            "none" :
            "block";
    });

    sendMailBtn.parentElement.addEventListener("mouseleave", () => {
        infoTxt.style.display = "none";
    });

    const listenToMailSend = () => {
        sendMailBtn.addEventListener("click", () => {
            //console.log('yes');
            // Do not allow to send mail if honeypot inputs are filled
            if (botTrap1.value != "" || botTrap2.value != "") return;

            const formData = {
                name: nameInput.value,
                company: companyInput.value,
                phone: phoneInput.value,
                email: emailInput.value,
                txt: txtInput.value,
            };
            sendMail(mailEndPoint, formData)
                .then((data) => {
                    displayFeedback(data);
                    sendMailWrapper.classList.add('dim');
                    sendMailWrapper.reset();
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        });
    };

    const sendMail = async (url, formData) => {
        const readyData = {
            formData: formData
        };
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(readyData),
        });
        return await response.json();
    };

    const displayFeedback = (data) => {
        alertsWrapper.className = data.type;
        alertsWrapper.querySelector("h3").innerText = data.header;
        alertsWrapper.querySelector("span").innerText = data.message;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formSubmission',
            'formType': 'Contact us',
            'formLocation': emailInput.value
        });
    };
    document.querySelector(".hde").style.display = "none";
    if (sendMailWrapper) {
        listenToMailSend();
    }

});

//  email copy to clipboard
let ems = document.querySelectorAll('.topemlcopy');
ems.forEach(em => {
    em.addEventListener('click', copyMe, false);
});

function copyMe(e) {
    let clickedelemnet = e.target;
    let copyText = clickedelemnet.dataset.copy;
    navigator.clipboard.writeText(copyText);
    clickedelemnet.classList.add('clicked');
}


// submenu mobile open
let winw = window.innerWidth;
let subopen = 0;
if (winw < 981) {
    let hamburger = document.getElementById("hamburger");
    let subtrig = document.getElementsByClassName("menu-item-has-children")[0];
    let sub = subtrig.getElementsByClassName("sub-menu")[0];
    hamburger.addEventListener("click", function () {
        document.body.classList.toggle('open');
        closesub();
    });
    subtrig.addEventListener("click", function () {
        if (subopen == 0) {
            subopen = 1;
            document.body.classList.toggle('subopen');
            subtrig.classList.toggle('opendown');
            var childs = sub.children;
            var h = 0;
            Array.prototype.forEach.call(childs, function (child) {
                h = h + 5 + child.clientHeight;
            });
            sub.style.height = h + 'px';
        } else {
            document.body.classList.toggle('subopen');
            subtrig.classList.toggle('opendown');
            sub.style.height = 0 + 'px';
            subopen = 0;
        }
    });

    function closesub() {
        if (subopen == 1) {
            subtrig.classList.toggle('opendown');
            document.body.classList.remove('subopen');
            sub.style.height = 0 + 'px';
            subopen = 0;
        }
    }
}